.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 5px;
  color: #4d4d4d;
  text-align: left; }
  .flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0.3em; }
  .flag-select .selected--flag--option {
    cursor: pointer;
    padding: 0 8px; }
    .flag-select .selected--flag--option:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999; }
    .flag-select .selected--flag--option:focus {
      outline: none; }
    .flag-select .selected--flag--option .country-label {
      padding-left: 9px; }
  .flag-select .country-flag {
    cursor: pointer; }
    .flag-select .country-flag .country-label {
      font-size: 1em;
      position: relative; }
  .flag-select .flag-options {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 160px;
    overflow: auto; }
    .flag-select .flag-options.to--left {
      right: 10px; }
  .flag-select .flag-option {
    cursor: pointer;
    padding: 0px 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap; }
    .flag-select .flag-option.has-label {
      padding: 0px 20px 3px 8px; }
    .flag-select .flag-option:hover {
      background: #eaeaea; }
    .flag-select .flag-option:focus {
      outline: none;
      background: #eaeaea; }
    .flag-select .flag-option .country-label {
      padding: 0 9px; }
  .flag-select .filterBox {
    width: 100%; }
    .flag-select .filterBox input {
      width: 90%;
      margin: 0 4%; }
      .flag-select .filterBox input:focus {
        outline: none; }
  .flag-select .arrow-down {
    color: #4d4d4d;
    padding: 2px; }
  .flag-select .hidden {
    display: none; }
  .flag-select .no--focus {
    pointer-events: none; }
